<template>
  <div>

   Admin Dasboard
  </div>
</template>

<script>
import {BCard, BCardBody, BCardHeader, BCardSubTitle, BCardTitle, BCol, BRow} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ApexLineAreaChart from '@/views/custom_app/customer/dashboard/components/ApexLineAreaChart.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCardSubTitle, BCardHeader, BCardTitle,
    BCard,
    BCardBody,
    ApexLineAreaChart,
    BRow,
    StatisticCardHorizontal,
    BCol,
    AppTimeline,
    AppTimelineItem,
    flatPickr,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
