<template>
  <div v-if="userData.role == 'superadmin'"><admin-dashboard /></div>
  <div v-else-if="userData.role == 'client'"><customer-dashboard /></div>
</template>

<script>
import CustomerDashboard from "@/views/custom_app/customer/dashboard/CustomerDashboard.vue"
import AdminDashboard from "@/views/custom_app/admin/dashboard/AdminDashboard.vue"
import {getUserData} from "@/auth/utils";

export default {
  components: {
    CustomerDashboard,
    AdminDashboard,
  },
  data() {
    return {
      userData: null,
    }
  },
  created() {
    this.userData = getUserData()
  },
}
</script>

<style lang="scss">
</style>
