<template>
  <div>

    <b-row class="mx-3">
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ActivityIcon"
          statistic="235.05"
          color="warning"
          statistic-title="Anlık Güç(kW)"
        />
      </b-col>
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="GridIcon"
            color="success"
            statistic="1,410"
            statistic-title="Günlük Üretilen(kWh)"
        />
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ChevronDownIcon"
          color="danger"
          statistic="0"
          statistic-title="Günlük Tüketilen(kWh)"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="DollarSignIcon"
          color="primary"
          statistic="2.820"
          statistic-title="Günlük Gelir(TL)"
        />
      </b-col>
    </b-row>
    <b-row class="mx-3">
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="SunIcon"
          statistic="%95"
          color="warning"
          statistic-title="Anlık Performans"
        />
      </b-col>
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="LayersIcon"
            color="success"

            statistic="79.200"
            statistic-title="Toplam Üretilen(mWh)"
        />
      </b-col>

      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="ChevronsDownIcon"
          color="danger"
          statistic="0"
          statistic-title="Toplam Tüketilen(kWh)"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="DatabaseIcon"
          color="primary"
          statistic="155.210"
          statistic-title="Toplam Gelir(TL)"
        />
      </b-col>
    </b-row>
    <b-row class="mx-3">
      <b-col
        lg="6"
      >
        <b-row>
          <b-col cols="12">
            <apex-line-area-chart fff="uretim" title="Aylık Üretim" />
          </b-col>
          <b-col cols="12">
            <apex-line-area-chart fff="tuketim" title="Aylık Tüketim" />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        lg="6"
      >
        <b-card  no-body>
          <b-card-header class="p-25">
            <!-- title and subtitle -->
            <div>
              <b-card-title >
                Uyarılar
              </b-card-title>
<!--              <b-card-sub-title>Commercial networks</b-card-sub-title>-->
            </div>
            <!--/ title and subtitle -->

            <!-- datepicker -->
<!--            <div class="d-flex align-items-center">
              <feather-icon
                  icon="CalendarIcon"
                  size="16"
              />
              <flat-pickr
                  v-model="rangePicker"
                  :config="{ mode: 'range'}"
                  class="form-control flat-picker bg-transparent border-0 shadow-none"
                  placeholder="YYYY-MM-DD"
              />
            </div>-->
            <!-- datepicker -->
          </b-card-header>
          <b-card-body class="m-1">
          <app-timeline>
            <app-timeline-item
                title="Meteoroloji"
                subtitle="Bugün maksimum üretim ön görülmektedir."
                icon="SunIcon"
                time="1 Saat Önce"
                variant="info"/>
            <app-timeline-item
                title="Performans Hatası (AI)"
                subtitle="Panel temizliği sonrasında performansta %4'lük bir artış öngörülmektedir."
                icon="CpuIcon"
                time="1 Saat Önce"
                variant="danger"/>
            <app-timeline-item
                title="Performans Hatası (AI)"
                subtitle="Inverter değişimi önerilmektedir."
                icon="CpuIcon"
                time="2 Saat Önce"
                variant="warning"/>
            <app-timeline-item
                title="Bağlantı Hatası"
                subtitle="Bağlantı Koptu!"
                icon="WarningIcon"
                time="8 Saat Önce"
                variant="danger"/>
          </app-timeline>

<!--            <app-timeline-item-->
<!--                title="Last milestone remain"-->
<!--                subtitle="You are just one step away from your goal"-->
<!--                icon="InfoIcon"-->
<!--                time="3 minutes ago"-->
<!--                variant="info"-->
<!--            />-->

<!--            <app-timeline-item-->
<!--                title="Your are running low on time"-->
<!--                subtitle="Only 30 minutes left to finish milestone"-->
<!--                icon="ClockIcon"-->
<!--                time="21 minutes ago"-->
<!--                variant="warning"-->
<!--            />-->

<!--            <app-timeline-item-->
<!--                title="Client Meeting"-->
<!--                subtitle="New event has been added to your schedule"-->
<!--                icon="UserIcon"-->
<!--                time="36 minutes ago"-->
<!--            />-->

<!--            <app-timeline-item-->
<!--                title="Product Design"-->
<!--                subtitle="Product design added in workflow"-->
<!--                icon="GridIcon"-->
<!--                time="1 hour ago"-->
<!--                variant="success"-->
<!--            />-->
<!--          </app-timeline>-->
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BCard, BCardBody, BCardHeader, BCardSubTitle, BCardTitle, BCol, BRow} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ApexLineAreaChart from '@/views/custom_app/customer/dashboard/components/ApexLineAreaChart.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCardSubTitle, BCardHeader, BCardTitle,
    BCard,
    BCardBody,
    ApexLineAreaChart,
    BRow,
    StatisticCardHorizontal,
    BCol,
    AppTimeline,
    AppTimelineItem,
    flatPickr,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
